import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  DeleteButton,
  EditButton,
  Table,
  TableActions,
  Tables,
} from "./inventorydetails";

import {
  CloseFilter,
  DownloadExcelWrapper,
  Filter,
  FilterTitle,
} from "../../utils/stylesbase";

import StoreContext from "../../components/Store/Context";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { API_URL } from "../../utils/env";

const convertDate = (date) => {
  if (!date) return "";
  date = date.split("/");
  const day = date[0];
  const month = date[1];
  const year = date[2];

  return new Date(`${year}-${month}-${day}`);
};

const convertDateBRtoEN = (date) => {
  date = date.split("/");
  const day = date[0];
  const month = date[1];
  const year = date[2];

  return `${year}-${month}-${day}`;
};

const InventoryDetails = () => {
  const {
    selectedFiltersInventoryDetails: selectedFilters,
    setSelectedFiltersInventoryDetails: setSelectedFilters,
    inventoryDetailsIsFiltered,
    setInventoryDetailsIsFiltered,
    userPermissions,
  } = useContext(StoreContext);

  const [ticketsDetails, setTicketsDetails] = useState([]);
  const [filteredTicketsDetails, setFilteredTicketsDetails] = useState([]);
  const [activeFilter, setActiveFilter] = useState(false);
  const [filtersOptions, setFiltersOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState(0);
  const [selectedLines, setSelectedLines] = useState([]);

  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const columnsNames = [
    {
      code: "registeredDateFormated",
      name: "Date of Registration",
    },
    {
      code: "buyer",
      name: "Buyer",
    },
    {
      code: "authorizing",
      name: "Authorizing",
    },
    {
      code: "purchaseDate",
      name: "Purchase Date",
    },
    {
      code: "ticketProvider",
      name: "Ticket Provider",
    },
    {
      code: "date",
      name: "Ticket Date",
    },
    {
      code: "sector",
      name: "Ticket",
    },
    {
      code: "quantity",
      name: "Quantity",
    },
    {
      code: "price",
      name: "Price",
    },
    {
      code: "createdBy",
      name: "Created By",
    },
    {
      code: "lastEditBy",
      name: "Last Edit By",
    },
  ];

  const [dateFilters, setDateFilters] = useState({
    registeredDateFormated: {
      start: "0001-01-02",
      end: "9999-12-08",
    },
  });

  const table2Ref = useRef(null);

  const deleteTicket = useCallback(
    (ticketId) => {
      if ([5].indexOf(userPermissions) !== -1) return;
      MySwal.fire({
        title: "Are you sure you want to delete the ticket?",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          fetch(`${API_URL}tickets/delete-from-inventory.php?id=${ticketId}`, {
            method: "POST",
          })
            .then((response) => response.json())
            .then((response) =>
              Swal.fire("Ticket removed successfully!!", "", "success").then(
                () => {
                  return navigate(0);
                }
              )
            );
        }
      });
    },
    [userPermissions]
  );

  const editInternalPurchase = useCallback((internalPurchaseId) => {
    navigate(`/internal-purchase-update?id=${internalPurchaseId}`);
  }, []);

  const openFilter = useCallback(
    (filter) => {
      setSearch("");
      setActiveFilter(filter);
      let options = [];
      ticketsDetails.forEach((ticket) => {
        if (options.indexOf(ticket[filter]) === -1) {
          let valid = true;
          selectedFilters.forEach((selectedFilter) => {
            if (
              selectedFilter.selecteds.indexOf(
                ticket[selectedFilter.attributeName]
              ) === -1 &&
              selectedFilter.attributeName !== filter
            )
              valid = false;
          });
          if (valid) options.push(ticket[filter]);
        }
      });
      options = options.sort((a, b) => (a > b ? 1 : -1));
      setFiltersOptions(options);
    },
    [ticketsDetails, setActiveFilter, selectedFilters]
  );

  const searchFilters = useCallback(
    (e) => {
      const search = e.target.value;
      const filter = activeFilter;
      setSearch(search);
      let options = [];
      ticketsDetails.forEach((ticket) => {
        if (options.indexOf(ticket[filter]) === -1)
          options.push(ticket[filter]);
      });
      options = options.filter((a) =>
        a ? a.toLowerCase().includes(search.toLowerCase()) : false
      );
      options = options.sort((a, b) => (a > b ? 1 : -1));
      setFiltersOptions(options);
    },
    [activeFilter, ticketsDetails]
  );

  const closeFilter = useCallback(() => {
    setActiveFilter(false);
  }, []);

  const changeFilter = useCallback(
    (e, attribute, option) => {
      let newFilters = selectedFilters;
      if (e.target.checked) {
        if (newFilters.find((item) => item.attributeName === attribute)) {
          newFilters
            .find((item) => item.attributeName === attribute)
            .selecteds.push(option);
        } else {
          newFilters.push({ attributeName: attribute, selecteds: [option] });
        }
      } else {
        newFilters
          .find((item) => item.attributeName === attribute)
          .selecteds.splice(
            newFilters
              .find((item) => item.attributeName === attribute)
              .selecteds.indexOf(option),
            1
          );
      }

      setSelectedFilters(newFilters);
      setReset(Math.random());
    },
    [selectedFilters, activeFilter]
  );

  const changeDateFilter = useCallback(
    (e, filter, id) => {
      let newDateFilters = dateFilters;
      newDateFilters[filter][id] = e.target.value;
      setDateFilters(newDateFilters);
      setReset(Math.random());
    },
    [dateFilters]
  );

  const checkFilterIsActive = useCallback(
    (option) => {
      if (
        selectedFilters.find((item) => item.attributeName === activeFilter) &&
        selectedFilters
          .find((item) => item.attributeName === activeFilter)
          .selecteds.indexOf(option) !== -1
      )
        return true;
      else return false;
    },
    [activeFilter, selectedFilters, reset]
  );

  const checkIfColumnIsFiltered = useCallback(
    (column) => {
      let filters = [];

      ticketsDetails.forEach((ticket) => {
        if (filters.indexOf(ticket[column]) === -1)
          filters.push(ticket[column]);
      });

      const total = filters.length;

      if (
        !selectedFilters.find((filter) => filter.attributeName === column) ||
        !selectedFilters.find((filter) => filter.attributeName === column)
          .selecteds
      )
        return false;

      return (
        selectedFilters.find((filter) => filter.attributeName === column)
          .selecteds.length !== total
      );
    },
    [selectedFilters, ticketsDetails]
  );

  const selectAll = useCallback(() => {
    let newFilters = selectedFilters;

    let filter = newFilters.find(
      (filter) => filter.attributeName === activeFilter
    );

    ticketsDetails.forEach((ticket) => {
      if (filter.selecteds.indexOf(ticket[activeFilter]) === -1)
        filter.selecteds.push(ticket[activeFilter]);
    });

    newFilters.find(
      (filter) => filter.attributeName === activeFilter
    ).selecteds = filter.selecteds;

    setSelectedFilters(newFilters);
    setReset(Math.random());
  }, [activeFilter, selectedFilters]);

  const clearAll = useCallback(() => {
    let newFilters = selectedFilters;
    newFilters.find(
      (filter) => filter.attributeName === activeFilter
    ).selecteds = [];
    setSelectedFilters(newFilters);
    setReset(Math.random());
  }, [activeFilter, selectedFilters]);

  const resetFilters = useCallback(() => {
    //Seleciona todos os filtros possíveis
    let filters = [];

    columnsNames.forEach((attribute) => {
      ticketsDetails.forEach((ticket) => {
        if (filters.find((item) => item.attributeName === attribute.code)) {
          if (
            filters
              .find((item) => item.attributeName === attribute.code)
              .selecteds.indexOf(ticket[attribute.code]) === -1
          )
            filters
              .find((item) => item.attributeName === attribute.code)
              .selecteds.push(ticket[attribute.code]);
        } else {
          filters.push({
            attributeName: attribute.code,
            selecteds: [ticket[attribute.code]],
          });
        }
      });
    });
    setSelectedFilters(filters);
  }, [ticketsDetails]);

  const toggleSelectedLine = useCallback(
    (tourId) => {
      let newSelectedLines = selectedLines;
      if (newSelectedLines.indexOf(tourId) === -1)
        newSelectedLines.push(tourId);
      else newSelectedLines.splice(newSelectedLines.indexOf(tourId), 1);
      setSelectedLines(newSelectedLines);
      setReset(Math.random());
    },
    [selectedLines]
  );

  useEffect(() => {
    fetch(`${API_URL}tickets/inventory-details.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        setTicketsDetails(response);
        setFilteredTicketsDetails(response);

        if (!inventoryDetailsIsFiltered) {
          if (response.length === 0) return;
          setInventoryDetailsIsFiltered(true);
          //Seleciona todos os filtros possíveis
          let filters = [];

          columnsNames.forEach((attribute) => {
            response.forEach((ticket) => {
              if (
                filters.find((item) => item.attributeName === attribute.code)
              ) {
                if (
                  filters
                    .find((item) => item.attributeName === attribute.code)
                    .selecteds.indexOf(ticket[attribute.code]) === -1
                )
                  filters
                    .find((item) => item.attributeName === attribute.code)
                    .selecteds.push(ticket[attribute.code]);
              } else {
                filters.push({
                  attributeName: attribute.code,
                  selecteds: [ticket[attribute.code]],
                });
              }
            });
          });
          setSelectedFilters(filters);

          //Seta a maior e menor data de RegisteredDate
        let registeredDateStart = "";
        let registeredDateEnd = "";
        response.forEach((ticket) => {
          if (registeredDateStart === "") {
            registeredDateStart = ticket.registeredDateFormated;
            registeredDateEnd = ticket.registeredDateFormated;
          } else {
            if (
              convertDate(ticket.registeredDateFormated) <
              convertDate(registeredDateStart)
            )
              registeredDateStart = ticket.registeredDateFormated;
            if (
              convertDate(ticket.registeredDateFormated) >
              convertDate(registeredDateEnd)
            )
              registeredDateEnd = ticket.registeredDateFormated;
          }

          let dates = dateFilters;
          dates.registeredDateFormated.start =
            convertDateBRtoEN(registeredDateStart);
          dates.registeredDateFormated.end =
            convertDateBRtoEN(registeredDateEnd);
        });
        }
      });
  }, []);

  //Atualiza os tickets filtrados
  useEffect(() => {
    const filteredItems = ticketsDetails.filter((ticket) => {
      let ok = true;
      selectedFilters.forEach((filter) => {
        if (filter.selecteds.indexOf(ticket[filter.attributeName]) === -1) {
          ok = false;
          return;
        }
      });
      if (
        convertDate(ticket.registeredDateFormated) <
          new Date(dateFilters.registeredDateFormated.start) ||
        convertDate(ticket.registeredDateFormated) >
          new Date(dateFilters.registeredDateFormated.end)
      ) {
        ok = false;
      }

      return ok;
    });
    setFilteredTicketsDetails(filteredItems);
  }, [selectedFilters, reset, ticketsDetails, dateFilters]);

  return (
    <>
      <DownloadExcelWrapper>
        <DownloadTableExcel
          filename="Inventory-Details"
          sheet="inventory-details"
          currentTableRef={table2Ref.current}
        >
          <Button variant="outlined" style={{ marginBottom: "20px" }}>
            Export to excel
          </Button>
        </DownloadTableExcel>
      </DownloadExcelWrapper>
      <Tables>
        <Table ref={table2Ref}>
          <thead>
            <tr>
              {columnsNames.map((column) => {
                return (
                  <th
                    onClick={() => openFilter(column.code)}
                    className={`${
                      checkIfColumnIsFiltered(column.code) ? "active" : ""
                    }`}
                    key={column.code}
                  >
                    {column.name}
                  </th>
                );
              })}
              <th className="sticky-right"></th>
            </tr>
          </thead>
          <tbody>
            {filteredTicketsDetails.map((ticket) => {
              return (
                <tr
                  key={ticket.id}
                  onClick={() => toggleSelectedLine(ticket.id)}
                  className={
                    selectedLines.indexOf(ticket.id) !== -1 ? "selected" : ""
                  }
                >
                  <td>{ticket.registeredDateFormated}</td>
                  <td>{ticket.buyer}</td>
                  <td>{ticket.authorizing}</td>
                  <td>{ticket.purchaseDate}</td>
                  <td>{ticket.ticketProvider}</td>
                  <td>{ticket.date}</td>
                  <td>{ticket.sector}</td>
                  <td>{ticket.quantity}</td>
                  <td>{ticket.price}</td>
                  <td>{ticket.createdBy}</td>
                  <td>{ticket.lastEditBy}</td>
                  <td className="actions sticky-right">
                    <EditButton
                      onClick={() =>
                        editInternalPurchase(ticket.internalPurchaseId)
                      }
                    />
                    <DeleteButton
                      onClick={() => deleteTicket(ticket.ticketId)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tables>
      <Filter opened={(activeFilter !== false).toString()}>
        <FilterTitle>
          Filtering{" "}
          {activeFilter &&
            columnsNames.find((filter) => activeFilter === filter.code).name}
        </FilterTitle>
        <CloseFilter onClick={() => closeFilter()} />
        {activeFilter === "registeredDateFormated" ? (
          <div className="date-filter">
            <TextField
              id={`start-${activeFilter}`}
              label="Start Date"
              variant="outlined"
              name={`start-${activeFilter}`}
              type="date"
              value={dateFilters.registeredDateFormated.start}
              onChange={(e) => changeDateFilter(e, activeFilter, "start")}
            />
            <TextField
              id={`end-${activeFilter}`}
              label="End Date"
              variant="outlined"
              name={`end-${activeFilter}`}
              type="date"
              value={dateFilters.registeredDateFormated.end}
              onChange={(e) => changeDateFilter(e, activeFilter, "end")}
            />
          </div>
        ) : (
          <ul status={reset}>
            <div className="filters-actions">
              <span onClick={selectAll}>Select All</span>
              <span onClick={clearAll}>Clear All</span>
            </div>
            <div className="filters-search">
              <TextField
                id="search"
                label="Search"
                variant="outlined"
                name="search-filter"
                value={search}
                onChange={searchFilters}
              />
            </div>
            <div className="filters-options">
              {filtersOptions.map((option) => {
                return (
                  <li key={option}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={option}
                            name={option}
                            onChange={(e) =>
                              changeFilter(e, activeFilter, option)
                            }
                            checked={checkFilterIsActive(option)}
                          />
                        }
                        label={
                          activeFilter === "fulfilled"
                            ? option === "1"
                              ? "Yes"
                              : "No"
                            : option
                        }
                      />
                    </FormGroup>
                  </li>
                );
              })}
            </div>
          </ul>
        )}
      </Filter>
    </>
  );
};

export default InventoryDetails;
