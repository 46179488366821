import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Button,
  Switch,
  Autocomplete,
} from "@mui/material";

import Sidebar from "../../components/Sidebar";
import {
  FulfillButton,
  Main,
  RemoveFulfillButton,
  SearchWrapper,
  SubTitle,
  Table,
  Tables,
  Title,
} from "./quicksearch";
import {
  CloseFulfillModal,
  Content,
  DownloadExcelWrapper,
  FormBox,
  FulfillModal,
  FulfillModalTitle,
  Overlay,
} from "../../utils/stylesbase";

import StoreContext from "../../components/Store/Context";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { API_URL } from "../../utils/env";

const convertDate = (date) => {
  date = date.split("/");
  const day = date[0];
  const month = date[1];
  const year = date[2];

  return new Date(`${year}-${month}-${day}`);
};

const convertDateBRtoEN = (date) => {
  date = date.split("/");
  const day = date[0];
  const month = date[1];
  const year = date[2];

  return `${year}-${month}-${day}`;
};

const QuickSearch = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const {
    sidebarClosed,
    userName,
    userPermissions,
    setSidebarClosed,
  } = useContext(StoreContext);

  const [reset, setReset] = useState(0);
  const [refSearch, setRefSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [refOptions, setRefOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [fulfillOption, setFulfillOption] = useState("add");
  const [fulfillModalOpened, setFulfillModalOpened] = useState(false);
  const [fulfillSeveralModalOpened, setFulfillSeveralModalOpened] =
    useState(false);
  const [recordsToFulfill, setRecordsToFulfill] = useState([]);
  const [recordsToFulfillSeveral, setRecordsToFulfillSeveral] = useState([]);
  const [selectedOrderRef, setSelectedOrderRef] = useState("");
  const [transfers, setTransfers] = useState([]);
  const [deliveries, setDeliveries] = useState([]);

  const tableRef = useRef(null);
  
  const columnsNames = [
    {
      code: "orderRef",
      name: "#",
    },
    {
      code: "orderPlataform",
      name: "Plataform",
    },
    {
      code: "name",
      name: "Name",
    },
    {
      code: "date",
      name: "Tickets Date",
    },
    {
      code: "sector",
      name: "Tickets",
    },
    {
      code: "pax",
      name: "Pax",
    },
    {
      code: "contact",
      name: "Contact",
    },
    {
      code: "whatsapp",
      name: "Whatsapp",
    },
    {
      code: "fulfilled",
      name: "Fulfilled",
    }
  ];


  const transfersColumnsNames = [
    {
      code: "orderRef",
      name: "#",
    },
    {
      code: "plataform",
      name: "Plataform",
    },
    {
      code: "name",
      name: "Name",
    },
    {
      code: "ticketsDate",
      name: "Tickets Date",
    },
    {
      code: "sector",
      name: "Tickets",
    },
    {
      code: "pax",
      name: "Pax",
    },
    {
      code: "contact",
      name: "Contact",
    },
    {
      code: "fulfilled",
      name: "Fulfilled",
    }
  ];


  const deliveriesColumnsNames = [
    {
      code: "orderRef",
      name: "#",
    },
    {
      code: "plataform",
      name: "Plataform",
    },
    {
      code: "name",
      name: "Name",
    },
    {
      code: "ticketsDate",
      name: "Tickets Date",
    },
    {
      code: "contact",
      name: "Contact",
    },
    {
      code: "fulfilled",
      name: "Fulfilled",
    }
  ];

  const fulfillTicket = useCallback(
    async (orderRef, option) => {
      if ([5].indexOf(userPermissions) !== -1) return;
      fetch(`${API_URL}fulfill/get-all.php?order-ref=${orderRef}`)
        .then((response) => response.json())
        .then((response) => {
          setFulfillOption(option);
          setRecordsToFulfill(response);
          setSelectedOrderRef(orderRef);
          setReset(Math.random());
          setFulfillModalOpened(true);
        });
    },
    [userName, userPermissions]
  );

  const onchange = useCallback((e) => {
    if(e.target.name === "ref"){
      if(e.target && e.target.value && e.target.value.length >= 3){
        setRefSearch(e.target.value);
        fetch(`${API_URL}tickets/quick-search.php?ref=${e.target.value}&name=${nameSearch}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((response) => {
          setRefOptions([...new Set(response.orders.map(order => order.orderRef))]);
          if(e.target.finish) {
            setTickets(response.orders);
            setTransfers(response.transfers);
            setDeliveries(response.delivery);
          };
          });
      } else {
        setRefSearch("");
        setRefOptions([]);
        setTickets([]);
        setTransfers([]);
        setDeliveries([]);
      }
    } else if(e.target.name === "name"){
      if(e.target && e.target.value && e.target.value.length >= 3){
        setNameSearch(e.target.value);
        fetch(`${API_URL}tickets/quick-search.php?name=${e.target.value}&ref=${refSearch}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((response) => {
          setNameOptions([...new Set(response.orders.map(order => order.name))]);
          if(e.target.finish){
            setTickets(response.orders);
            setTransfers(response.transfers);
            setDeliveries(response.delivery);
          };
          });
      } else {
        setNameSearch("");
        setNameOptions([]);
        setTickets([]);
        setTransfers([]);
        setDeliveries([]);
      }
    }
  },[refSearch, nameSearch]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const elements = Array.from(e.target.elements);
      let body = [];
      elements.forEach((el) => {
        if (el.tagName === "INPUT") {
          const type = el.id.split("-")[0].trim();
          const id = el.id.split("-")[(1)].trim();
          if (el.checked) {
            body.push({
              type,
              id,
            });
          }
        }
      });
      if (fulfillOption === "add") {
        fetch(`${API_URL}fulfill/fulfill-several.php`, {
          method: "POST",
          body: JSON.stringify({ records: body, lastEditBy: userName }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
            } else {
              Swal.fire("Records fulfilled successfully!!", "", "success").then(
                () => {
                  return navigate(0);
                }
              );
            }
          });
      } else if (fulfillOption === "remove") {
        fetch(`${API_URL}fulfill/unfulfill-several.php`, {
          method: "POST",
          body: JSON.stringify({ records: body, lastEditBy: userName }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
            } else {
              Swal.fire(
                "Records fulfill removed successfully!!",
                "",
                "success"
              ).then(() => {
                return navigate(0);
              });
            }
          });
      }
    },
    [fulfillOption]
  );




  useEffect(() => {
    setSidebarClosed(true);
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <SubTitle>Quick</SubTitle>
        <Title>Search</Title>
        <SearchWrapper>
          <Autocomplete
            value={refSearch}
            options={refOptions}
            sx={{ width: 300 }}
            onChange={(e, newValue) =>
              onchange({
                target: {
                  name: "ref",
                  finish: true,
                  value: newValue,
                },
              })
            }
            ListboxProps={{
              onClick: (event) => {
                const clickedOption = refOptions.find(
                  (option) => option === event.target.innerText
                );
                if (clickedOption) {
                  onchange({
                    target: {
                      name: "ref",
                      finish: true,
                      value: clickedOption,
                    },
                  })
                }
              },
            }}
            renderInput={(params) => <TextField {...params} label="Order Ref" name="ref" onChange={onchange}/>}
          />
          <Autocomplete
            value={nameSearch}
            options={nameOptions}
            sx={{ width: 300 }}
            onChange={(e, newValue) =>
              onchange({
                target: {
                  name: "name",
                  finish: true,
                  value: newValue,
                },
              })
            }
            ListboxProps={{
              onClick: (event) => {
                const clickedOption = nameOptions.find(
                  (option) => option === event.target.innerText
                );
                if (clickedOption) {
                  onchange({
                    target: {
                      name: "name",
                      finish: true,
                      value: clickedOption,
                    },
                  })
                }
              },
            }}
            renderInput={(params) => <TextField {...params} label="Customer Name" name="name" onChange={onchange}/>}
          />
        </SearchWrapper>
        <Tables>
          <h1>Orders</h1>
          <Table>
            <thead>
              <tr>
                {columnsNames.map((column) => {
                  return (
                    <th
                      key={column.code}
                    >
                      {column.name}
                    </th>
                  );
                })}
                <th className="sticky-right"></th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((ticket) => {
                return (
                  <tr
                    key={ticket.ticket_id}
                    
                  >
                    <td>{ticket.orderRef}</td>
                    <td>{ticket.orderPlataform}</td>
                    <td>{ticket.name}</td>
                    <td>{ticket.date}</td>
                    <td>{ticket.sector}</td>
                   
                    <td>{ticket.pax}</td>
                    <td>{ticket.contact}</td>
                    <td>{ticket.whatsapp}</td>
                    <td>
                      {ticket.fulfilled === 1 ? 
                        <CheckBoxIcon value="yes" />
                       : 
                        <CheckBoxOutlineBlankIcon value="no" />
                      }
                    </td>
                    <td className="actions sticky-right">
                      {[1, 5, 3, 4].indexOf(userPermissions) !== -1 &&
                        (ticket.fulfilled === 1 ? (
                          <Tooltip title="Remove Fulfill" placement="top">
                            <RemoveFulfillButton
                              onClick={() =>
                                fulfillTicket(ticket.orderRef, "remove")
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Fulfill" placement="top">
                            <FulfillButton
                              onClick={() =>
                                fulfillTicket(ticket.orderRef, "add")
                              }
                            />
                          </Tooltip>
                        ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <h1>Transfer</h1>
          <Table>
            <thead>
              <tr>
                {transfersColumnsNames.map((column) => {
                  return (
                    <th
                      key={column.code}
                    >
                      {column.name}
                    </th>
                  );
                })}
                <th className="sticky-right"></th>
              </tr>
            </thead>
            <tbody>
              {transfers.map((transfer) => {
                return (
                  <tr
                    key={transfer.id}
                    
                  >
                    <td>{transfer.orderRef}</td>
                    <td>{transfer.plataform}</td>
                    <td>{transfer.name}</td>
                    <td>{transfer.ticketsDate}</td>
                    <td>{transfer.sector}</td>
                   
                    <td>{transfer.pax}</td>
                    <td>{transfer.contact}</td>
                    <td>
                      {transfer.fulfilled === 1 ? 
                        <CheckBoxIcon value="yes" />
                       : 
                        <CheckBoxOutlineBlankIcon value="no" />
                      }
                    </td>
                    <td className="actions sticky-right">
                      {[1, 5, 3, 4].indexOf(userPermissions) !== -1 &&
                        (transfer.fulfilled === 1 ? (
                          <Tooltip title="Remove Fulfill" placement="top">
                            <RemoveFulfillButton
                              onClick={() =>
                                fulfillTicket(transfer.orderRef, "remove")
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Fulfill" placement="top">
                            <FulfillButton
                              onClick={() =>
                                fulfillTicket(transfer.orderRef, "add")
                              }
                            />
                          </Tooltip>
                        ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <h1>Delivery</h1>
          <Table>
            <thead>
              <tr>
                {deliveriesColumnsNames.map((column) => {
                  return (
                    <th
                      key={column.code}
                    >
                      {column.name}
                    </th>
                  );
                })}
                <th className="sticky-right"></th>
              </tr>
            </thead>
            <tbody>
              {deliveries.map((delivery) => {
                return (
                  <tr
                    key={delivery.id}
                    
                  >
                    <td>{delivery.orderRef}</td>
                    <td>{delivery.plataform}</td>
                    <td>{delivery.name}</td>
                    <td>{delivery.ticketsDate}</td>
                    <td>{delivery.contact}</td>
                    <td>
                      {delivery.fulfilled === 1 ? 
                        <CheckBoxIcon value="yes" />
                       : 
                        <CheckBoxOutlineBlankIcon value="no" />
                      }
                    </td>
                    <td className="actions sticky-right">
                      {[1, 5, 3, 4].indexOf(userPermissions) !== -1 &&
                        (delivery.fulfilled === 1 ? (
                          <Tooltip title="Remove Fulfill" placement="top">
                            <RemoveFulfillButton
                              onClick={() =>
                                fulfillTicket(delivery.orderRef, "remove")
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Fulfill" placement="top">
                            <FulfillButton
                              onClick={() =>
                                fulfillTicket(delivery.orderRef, "add")
                              }
                            />
                          </Tooltip>
                        ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Tables>
        <Overlay opened={fulfillModalOpened.toString()}>
          <FulfillModal status={reset} opened={fulfillModalOpened.toString()}>
            <FulfillModalTitle>
              Select records to{" "}
              {fulfillOption === "add" ? "fulfill" : "remove fulfill"} on
              order-ref {selectedOrderRef}
            </FulfillModalTitle>
            <CloseFulfillModal onClick={() => setFulfillModalOpened(false)} />
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
            >
              {recordsToFulfill.map((record) => (
                <FormGroup key={`${record.id}-${record.type}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`${record.type} - ${record.id}`}
                        name={record.id}
                        record-type={record.type}
                        inputProps={{
                          "record-type": record.type,
                        }}
                      />
                    }
                    label={`${record.type} ${
                      record.date ? `- ${record.date}` : ""
                    } ${record.sector ? `- ${record.sector}` : ""}`}
                  />
                </FormGroup>
              ))}
              <Button variant="outlined" type="submit">
                Save
              </Button>
            </FormBox>
          </FulfillModal>
        </Overlay>
        <Overlay opened={fulfillSeveralModalOpened.toString()}>
          <FulfillModal
            status={reset}
            opened={fulfillSeveralModalOpened.toString()}
          >
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
            >
              <CloseFulfillModal
                onClick={() => setFulfillSeveralModalOpened(false)}
              />
              {recordsToFulfillSeveral.map((order, index) => {
                return (
                  <div
                    key={`${order.id}-${order.type}`}
                    className={`${
                      index !== recordsToFulfillSeveral.length - 1 &&
                      order.orderRef !==
                        recordsToFulfillSeveral[index + 1].orderRef &&
                      "m-bottom"
                    }`}
                  >
                    {(index === 0 ||
                      order.orderRef !==
                        recordsToFulfillSeveral[index - 1].orderRef) && (
                      <FulfillModalTitle>
                        Select records to fulfill on order-ref {order.orderRef}
                      </FulfillModalTitle>
                    )}

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`${order.type} - ${order.id}`}
                            name={order.id}
                            record-type={order.type}
                            inputProps={{
                              "record-type": order.type,
                            }}
                          />
                        }
                        label={`${order.type} ${
                          order.date ? `- ${order.date}` : ""
                        } ${order.sector ? `- ${order.sector}` : ""}`}
                      />
                    </FormGroup>
                  </div>
                );
              })}
              <Button variant="outlined" type="submit">
                Save
              </Button>
            </FormBox>
          </FulfillModal>
        </Overlay>
      </Content>
    </Main>
  );
};

export default QuickSearch;
